import axios from "axios"
import https from "https"
class W9FormApi {
  constructor() {
    this.api = axios.create(
      {
        withCredentials: true,
        httpsAgent: new https.Agent({
          rejectUnauthorized: false
        })
      }
    )
    this.api.defaults.baseURL = process.env.VUE_APP_API_URL
    this.api.defaults.timeout = 1000000

    this.postRequest = function (url, body) {
      return this.api.post(url, body)
    }

    this.getRequest = function (url, params) {
      return this.api.get(url, {
        params: params
      })
    }

  }
  // Login Request
  async login(data) {
    let url = "/Sso/login"
    return this.postRequest(url, data)
  }

  async submitFormData (data) {
    let url = '/W9/Form'
    return this.postRequest(url, data)
  }

  async getMemberDetails (pin) {
    let url = '/Members/Detail'
    return this.getRequest(url, {
      pin: pin
    })
  }
}

export default new W9FormApi()